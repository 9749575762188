import React, { Component } from 'react';
import { string, func, arrayOf, shape } from 'prop-types';

import { Helmet } from 'react-helmet';

import IntroOverlay from 'components/IntroOverlay/IntroOverlay';
import Main from 'components/styled/Main';

class Home extends Component {
  render() {
    return (
      <Main>
        <Helmet>
          <meta
            name="description"
            content="Drone and film 🎥 productions company by Mark Haasjes"
          />
        </Helmet>
        <IntroOverlay />
      </Main>
    );
  }
}

Home.propTypes = {
  Home: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      sources: arrayOf(
        shape({
          src: string.isRequired,
          type: string.isRequired,
        })
      ).isRequired,
      poster: string.isRequired,
    })
  ).isRequired,
  activeCaseId: string.isRequired,
  onCaseHover: func.isRequired,
};

export default Home;
