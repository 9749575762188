import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components/macro';
import { Helmet } from 'react-helmet';

import AppRoutes from 'AppRoutes';

import base from 'styles/global/base';
import fonts from 'styles/global/fonts';
import typography from 'styles/global/typography';
import colors from 'styles/theme/colors';
import variables from 'styles/theme/variables';

const GlobalStyle = createGlobalStyle`
  ${base}
  ${fonts}
  ${typography}
`;

class App extends Component {
  render() {
    return (
      <Router>
        <ThemeProvider theme={{ ...colors, ...variables }}>
          <Fragment>
            <GlobalStyle />
            <Helmet titleTemplate="%s | MΛRKΛBLE productions">
              <meta charSet="utf-8" />
              <meta
                name="description"
                content="MΛRKΛBLE productions is a Dutch drone and film productions company."
              />
            </Helmet>
            <Route render={renderProps => <AppRoutes {...renderProps} />} />
          </Fragment>
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
