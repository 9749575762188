export default {
  base: {
    Black: '#000000',
    White: '#ffffff',
  },
  brand: {
    Primary: '#4ED2B0',
    Secondary: '#086872',
    Dark: '#061d25',
    Light: '#E8F7F4',
  },
};
