import React, { Component } from 'react';
import { bool } from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Link, NavLink } from 'react-router-dom';

import { ReactComponent as LogoSmallSVG } from './logo-small.svg';

const Nav = styled.nav``;

const List = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: inline-block;
`;

const LogoSmall = styled(LogoSmallSVG)`
  width: 3.3em;
  height: auto;
  color: white;
  transition: color 150ms ease-in-out;
`;

const LogoLink = styled(Link)`
  display: block;
  padding: 0.5em;

  &:hover {
    ${LogoSmall} {
      color: ${({ theme }) => theme.brand.Primary};
    }
  }
`;

const StyledLink = styled(NavLink)`
  font-family: ${({ theme }) => theme.font.fontFamilyHead};
  font-size: 1em;
  font-weight: 400;
  padding-bottom: 0.25em;
  margin: 1em;
  color: white;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: all 150ms ease-out;

  &:hover,
  &.active:hover {
    color: ${({ theme }) => theme.brand.Primary};
    border-bottom: 2px solid ${({ theme }) => theme.brand.Primary};
  }

  &.active,
  &:focus {
    border-bottom: 2px solid white;
  }
`;

const invertedStyle = css`
  ${LogoSmall} {
    color: ${({ theme }) => theme.brand.Dark};
  }

  ${StyledLink} {
    color: ${({ theme }) => theme.brand.Dark};

    &:hover,
    &.active:hover {
      color: ${({ theme }) => theme.brand.Primary};
      border-bottom-color: ${({ theme }) => theme.brand.Primary};
    }

    &.active,
    &:focus {
      border-bottom-color: ${({ theme }) => theme.brand.Dark};
    }
  }
`;

const Container = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  z-index: 3;

  ${({ invertColors }) => invertColors && invertedStyle};

  @media screen and (min-width: 42rem) {
    padding: 1rem;
  }
`;

class Header extends Component {
  render() {
    return (
      <Container invertColors={this.props.invertColors}>
        <LogoLink to="/cases">
          <LogoSmall />
        </LogoLink>
        {!this.props.minimalDisplay && (
          <Nav>
            <List>
              <ListItem>
                <StyledLink to="/cases" activeClassName="active">
                  cases
                </StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/about" activeClassName="active">
                  about
                </StyledLink>
              </ListItem>
            </List>
          </Nav>
        )}
      </Container>
    );
  }
}

Header.propTypes = {
  invertColors: bool,
  minimalDisplay: bool,
};

export default Header;
