import React, { Component } from 'react';
import { arrayOf, string, shape, bool } from 'prop-types';
import styled from 'styled-components/macro';

const Styledvideo = styled.video`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: 50% 50%;
`;

class BackgroundVideo extends Component {
  constructor(props) {
    super(props);
    this.videoPlayer = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // Pause the video when the pause prop is changed to true
    if (this.props.pause !== prevProps.pause && this.props.pause === true) {
      this.pauseVideo();
    }
    // Play the video when the pause prop is changed to false
    if (this.props.pause !== prevProps.pause && this.props.pause === false) {
      this.playVideo();
    }
  }

  playVideo = () => this.videoPlayer.current && this.videoPlayer.current.play();

  pauseVideo = () =>
    this.videoPlayer.current && this.videoPlayer.current.pause();

  render() {
    const { sources, poster } = this.props;

    return (
      <Styledvideo
        ref={this.videoPlayer}
        preload="meta"
        autoPlay
        loop
        muted
        playsInline
        poster={poster}
      >
        {sources.map(source => (
          <source key={source.src} src={source.src} type={source.type} />
        ))}
      </Styledvideo>
    );
  }
}

BackgroundVideo.defaultProps = {
  pause: false,
};

BackgroundVideo.propTypes = {
  sources: arrayOf(
    shape({
      src: string.isRequired,
      type: string.isRequired,
    })
  ).isRequired,
  poster: string.isRequired,
  pause: bool,
};

export default BackgroundVideo;
