import React from 'react';
import { bool } from 'prop-types';
import styled from 'styled-components/macro';

import { Hero } from 'styles/theme/typography';

const SVG = styled.svg`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* fallback */
  width: 100vw;
  height: 100%; /* fallback */
  height: 100vh;
  z-index: 3;
  transition: opacity 500ms ease-in-out;

  ${({ hide }) => hide && `opacity: 0;`};
`;

const MaskRect = styled.rect`
  fill: white;
`;

const Rect = styled.rect`
  fill: white;
  mask: url(#mask);
`;

const Text = styled.text`
  ${Hero};
  text-anchor: middle;
  dominant-baseline: middle;
`;

const BackgroundVideoWithMask = ({ title, hide }) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid slice"
      hide={hide}
    >
      <defs>
        <mask id="mask" x="0" y="0">
          <MaskRect x="0" y="0" width="100%" height="100%" />
          <Text x="50%" y="50%">
            {title}
          </Text>
        </mask>
      </defs>
      <Rect x="0" y="0" width="100%" height="100%" />
    </SVG>
  );
};

BackgroundVideoWithMask.propTypes = {
  hide: bool,
};

export default BackgroundVideoWithMask;
