import React from 'react';
import styled from 'styled-components/macro';

const StyledMain = styled.main`
  flex: 0 1 auto;
  padding: 5rem 1rem 1rem;

  @media screen and (min-width: 42rem) {
    padding: 5rem 3.5rem 1rem;
  }
  @media screen and (min-width: 75rem) {
    padding: 5rem 3.5rem 1rem;
  }
`;

const Main = ({ children, className }) => {
  return <StyledMain className={className}>{children}</StyledMain>;
};

export default Main;
