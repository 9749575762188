import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import FullscreenTextMask from 'components/FullscreenTextMask/FullscreenTextMask';

import { defaultButton } from 'styles/base-classes';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
`;

const StyledLink = styled(Link)`
  position: relative;
  bottom: 20%;
  z-index: 4;
  ${defaultButton};
`;

class IntroOverlay extends Component {
  render() {
    return (
      <Container>
        <StyledLink to="/cases">Enter site</StyledLink>
        <FullscreenTextMask title="MΛRKΛBLE" />
      </Container>
    );
  }
}

export default IntroOverlay;
