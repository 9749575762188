import React, { Component } from 'react';
import { string, func, shape } from 'prop-types';
import { casesProps } from 'propTypes';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import Main from 'components/styled/Main';
import ProjectDetail from 'components/ProjectDetail/ProjectDetail';

const StyledMain = styled(Main)`
  align-self: flex-end;
  flex: 1 1 100%;
  padding: 0;
  overflow: hidden;
`;

const Container = styled.div`
  position: relative;
  margin-top: calc(70vh);
  padding: 1rem;
  background: ${({ theme }) => theme.brand.Dark};
  z-index: 1;

  @media screen and (min-width: 42rem) {
    padding: 2rem 3rem;
  }
  @media screen and (min-width: 75rem) {
    padding: 2rem 7rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 101vw;
    height: 5.2vw;
    background-color: ${({ theme }) => theme.brand.Dark};
    transform-origin: top left;
    transform: rotate(-3deg);
  }
`;

class Case extends Component {
  constructor(props) {
    super(props);

    const { caseId } = props.match.params;
    const project = props.cases.find(project => project.id === caseId);

    props.setActiveCaseId(caseId);
    this.state = { project };
  }

  render() {
    const { project } = this.state;

    if (!project) return <Redirect to="/404" />;

    return (
      <StyledMain>
        <Helmet>
          <title>{project.title}</title>
          <meta name="description" content={project.description} />
        </Helmet>
        <Container>
          <ProjectDetail project={project} />
        </Container>
      </StyledMain>
    );
  }
}

Case.propTypes = {
  cases: casesProps,
  match: shape({
    params: shape({
      caseId: string.isRequired,
    }).isRequired,
  }).isRequired,
  setActiveCaseId: func,
};

export default Case;
