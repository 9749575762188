import React from 'react';
import { string, func } from 'prop-types';
import { casesProps } from 'propTypes';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import detectIt from 'detect-it';

import { Head, SubHead } from 'styles/theme/typography';

const { hasTouch, hasMouse } = detectIt;

const PosedList = posed.ol({
  enter: { staggerChildren: 50 },
  exit: { staggerChildren: 20, staggerDirection: -1 },
});

const List = styled(PosedList)`
  margin: 0;
  padding: 0;
`;

// Prevent 'isActive' attribute from being added to `<Link />`
const StyledLink = styled(({ isActive, theme, ...rest }) => <Link {...rest} />)`
  ${Head};
  display: block;
  padding: 0.04em 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  opacity: 0.5;
  transition: opacity 250ms ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;

      ${StyledLink} {
        text-shadow: 0.06em 0.06em 0.12em rgba(0, 0, 0, 0.1);
      }
    `};
`;

const ListItem = styled.li`
  list-style: none;
  margin: 1em 0;
`;

const AnimatedListItem = posed(ListItem)({
  enter: { x: 0, opacity: 1 },
  exit: { x: 50, opacity: 0 },
});

const Subtitle = styled.sup`
  ${SubHead};
  font-size: 0.4em;
  display: inline-block;
  margin: 0.5em 1em 0 0;
  color: white;
`;

const CaseList = ({ isHome, cases, activeCaseId, onCaseHover }) => {
  return (
    <List>
      {!isHome &&
        cases.map((item, index) => {
          const caseNumber = index + 1;
          const caseNumberLeadingZero =
            caseNumber < 10 ? `0${caseNumber}` : `${caseNumber}`;

          return (
            <AnimatedListItem key={item.id}>
              <StyledLink
                to={`/cases/${item.id}`}
                onMouseEnter={() => hasMouse && onCaseHover(item.id)}
                onTouchStart={() => hasTouch && onCaseHover(item.id)}
                isActive={item.id === activeCaseId}
              >
                <Subtitle>{caseNumberLeadingZero}</Subtitle>
                {item.shortName}
              </StyledLink>
            </AnimatedListItem>
          );
        })}
    </List>
  );
};

CaseList.propTypes = {
  cases: casesProps.isRequired,
  activeCaseId: string.isRequired,
  onCaseHover: func.isRequired,
};

export default CaseList;
