import React, { Component } from 'react';
import { string, arrayOf, shape } from 'prop-types';
import styled from 'styled-components';

import BackgroundVideo from 'components/BackgroundVideo/BackgroundVideo';

const List = styled.ol`
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  display: block;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`;

class BackgroundVideoList extends Component {
  render() {
    return (
      <List>
        {this.props.videoList.map(video => {
          const isActive = video.id === this.props.activeVideoId;
          return (
            <ListItem key={video.id} isActive={isActive}>
              <BackgroundVideo
                sources={video.sources}
                poster={video.poster}
                pause={!isActive}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

BackgroundVideoList.propTypes = {
  videoList: arrayOf(
    shape({
      title: string.isRequired,
      sources: arrayOf(
        shape({
          src: string.isRequired,
          type: string.isRequired,
        })
      ).isRequired,
      poster: string.isRequired,
    })
  ).isRequired,
  activeVideoId: string.isRequired,
};

export default BackgroundVideoList;
