import React, { Component } from 'react';
import { string } from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 65vw;
  height: 100%;
  margin-top: 10vh;
  margin-left: 0;
`;

const defaultTitleStyle = css`
  margin: 0 0 0.1em;
  font-family: ${({ theme }) => theme.font.fontFamilyHead};
  font-weight: 700;
  color: white;
  line-height: 1;
  background: ${({ theme }) => theme.brand.Primary};
`;

const Title = styled.h2`
  ${defaultTitleStyle};
  padding: 0.1em 0.2em;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 0.06em;

  @media screen and (max-width: 40em) {
    font-size: calc(1em + 0.05vw);
  }
`;

const Subtitle = styled.h3`
  ${defaultTitleStyle};
  padding: 0.1em;
  font-size: 4em;

  @media screen and (max-width: 40em) {
    font-size: calc(2.4em + 0.05vw);
  }
`;

class CaseInfo extends Component {
  render() {
    const { title, subtitle } = this.props;

    return (
      <Container>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Container>
    );
  }
}

CaseInfo.propTypes = {
  title: string,
  subtitle: string,
};

export default CaseInfo;
