import { css } from 'styled-components';

const fonts = css`
  /* Zwizz
  @font-face {
    font-family: 'Zwizz';
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/zwizz-regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Zwizz';
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/zwizz-bold.woff2') format('woff2');
  } */
`;

export default fonts;
