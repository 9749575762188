import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import posed from 'react-pose';

import Main from 'components/styled/Main';

import { ReactComponent as IconInstagram } from 'icons/icon-instagram.svg';
import { ReactComponent as IconFacebook } from 'icons/icon-facebook.svg';
import { ReactComponent as IconYouTube } from 'icons/icon-youtube.svg';
import { ReactComponent as IconTwitter } from 'icons/icon-twitter.svg';

const AnimatedParagraph = posed.p({
  enter: { x: 0, opacity: 1 },
  exit: { x: 50, opacity: 0 },
});

const Paragraph = styled(AnimatedParagraph)`
  max-width: 40em;
  font-size: 1.3em;
  font-family: ${({ theme }) => theme.font.fontFamilyHead};
  font-weight: 700;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 2.5em;

  @media screen and (min-width: 42rem) {
    font-size: 2em;
  }
  @media screen and (min-width: 75rem) {
    font-size: 2.2em;
  }
`;

const AnimatedFootnote = posed.footer({
  enter: { x: 0, opacity: 1, delay: 300 },
  exit: { x: 50, opacity: 0, delay: 100 },
});

const Footnote = styled(AnimatedFootnote)`
  font-size: 0.9em;
  margin: 0 0 2em;

  @media screen and (max-width: 40em) {
    font-size: calc(1em + 0.05vw);
  }
`;

const StaggeredList = posed.ul({
  enter: { staggerChildren: 50, delay: 300 },
  exit: { staggerChildren: 50, staggerDirection: -1 },
});

const List = styled(StaggeredList)`
  margin: 0 0 1em -0.5em;
  padding: 0;
  list-style: none;
`;

const AnimatedListItem = posed.li({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

const ListItem = styled(AnimatedListItem)`
  display: inline-block;
`;

const Hyperlink = styled.a`
  display: inline-block;
  padding: 0.5em;
  margin-right: 0.5em;
  vertical-align: middle;

  svg {
    fill: white;
    transition: fill 150ms ease-in-out;
  }

  &:hover,
  &:focus {
    svg {
      fill: ${({ theme }) => theme.brand.Primary};
    }
  }
`;

const Link = styled.a`
  display: inline-block;
  border-bottom: 1px dashed white;
  color: white;
  transition: color 150ms ease-in-out, border-color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.brand.Primary};
    border-color: ${({ theme }) => theme.brand.Primary};
  }
`;

class About extends Component {
  render() {
    return (
      <Main>
        <Helmet>
          <title>About</title>
          <meta
            name="description"
            content="I am Mark Haasjes a video producer based in Utrecht, The Netherlands."
          />
        </Helmet>
        <Paragraph>
          I am a Dutch video producer based in Utrecht, The Netherlands.
          <br />
          My expertise lies in creating videos for brands and companies /
          weddings / travel / real estate / commercials and more.
        </Paragraph>
        <List>
          <ListItem>
            <Hyperlink href="https://www.instagram.com/markableproductions/">
              <IconInstagram />
            </Hyperlink>
          </ListItem>
          <ListItem>
            <Hyperlink href="https://www.facebook.com/markableproductions/">
              <IconFacebook />
            </Hyperlink>
          </ListItem>
          <ListItem>
            <Hyperlink href="https://www.youtube.com/channel/UC3K-mXqf4KZKY5EqMg6-7jQ/">
              <IconYouTube />
            </Hyperlink>
          </ListItem>
          <ListItem>
            <Hyperlink href="https://twitter.com/_MARKABLE_/">
              <IconTwitter />
            </Hyperlink>
          </ListItem>
        </List>
        <Footnote>
          <Link href="mailto:info@markableproductions.com">
            Contact me via e-mail
          </Link>{' '}
          or reach out on social media.
        </Footnote>
      </Main>
    );
  }
}

export default About;
