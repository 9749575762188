import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { casesProps } from 'propTypes';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';

import CaseList from 'components/CaseList/CaseList';
import Main from 'components/styled/Main';

const StyledMain = styled(Main)`
  position: relative;
  z-index: 1;
`;

class Cases extends Component {
  render() {
    const { cases, activeCaseId, onCaseHover } = this.props;

    return (
      <StyledMain>
        <Helmet>
          <title>Cases</title>
          <meta name="description" content="Cases of MΛRKΛBLE productions." />
        </Helmet>
        <CaseList
          cases={cases}
          activeCaseId={activeCaseId}
          onCaseHover={onCaseHover}
        />
      </StyledMain>
    );
  }
}

Cases.propTypes = {
  cases: casesProps.isRequired,
  activeCaseId: string.isRequired,
  onCaseHover: func.isRequired,
};

export default Cases;
