import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';

import Home from 'pages/Home';
import Cases from 'pages/Cases';
import Case from 'pages/Case';
import About from 'pages/About';
import ErrorPage from 'pages/ErrorPage';

import Header from 'components/Header/Header';
import BackgroundVideoList from 'components/BackgroundVideoList/BackgroundVideoList';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const RoutesContainer = posed(Container)({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0, afterChildren: true },
});

class AppRoutes extends Component {
  constructor(props) {
    super(props);

    const cases = [
      {
        id: 'tt-run-19',
        shortName: "TT Run '19",
        title: 'TT Run 2019',
        description:
          'I had the honor to produce and film the promotion video for next years TT Run, which will be the 10th anniversary! On the 22nd of June 2019 "the holy race track" of the Dutch TT race circuit opens one day exclusively for runners who can run the TT Run. The TT Circuit is the world decor of the Dutch MotoGP. During this running event, the week before the Dutch TT starts, thousands of participants run together.',
        youTubeId: 'fj32tPHvCx0',
        poster:
          'https://markable.ams3.digitaloceanspaces.com/website/videos/cases/tt-run-promo/tt-run-promo-poster-v1-lq.jpg',
        sources: [
          {
            src:
              'https://markable.ams3.digitaloceanspaces.com/website/videos/cases/tt-run-promo/tt-run-promo-casevideo-v3-lq.mp4',
            type: 'video/mp4',
          },
        ],
      },
      {
        id: 'wilfried-wolterinck',
        shortName: 'Wilfried Wolterinck',
        title: 'Wilfried Wolterinck',
        description:
          'Wilfried Wolterinck built his own double wing airplane, a Kiebitz, which means lapwing in German. I had the honor to interview Wilfried and fly with him above the fortified city of Bourtange (Groningen, The Netherlands). Wilfried is very passioned about flying. Watch the video to see what him inspired to build his own plane.',
        youTubeId: 'k-fHpNddGLw',
        poster:
          'https://markable.ams3.digitaloceanspaces.com/website/videos/cases/wilfried-wolterinck/wilfried-wolterinck-poster-v1-lq.jpg',
        sources: [
          {
            src:
              'https://markable.ams3.digitaloceanspaces.com/website/videos/cases/wilfried-wolterinck/wilfried-wolterinck-casevideo-v1-lq.mp4',
            type: 'video/mp4',
          },
        ],
      },
      {
        id: 'tt-run-aftermovie',
        shortName: 'TT Run aftermovie',
        title: 'TT Run aftermovie',
        description:
          'Runners can experience the feeling of beeing on a real motor race circuit, the TT Circuit of the Dutch MotoGP in Assen. Every year, the week before the Dutch TT starts thousands participants gather to run during this special event.',
        poster:
          'https://markable.ams3.digitaloceanspaces.com/website/videos/cases/tt-run-aftermovie/tt-run-aftermovie-poster-v1-lq.jpg',
        youTubeId: 'nPA0JomXNfA',
        sources: [
          {
            src:
              'https://markable.ams3.digitaloceanspaces.com/website/videos/cases/tt-run-aftermovie/tt-run-aftermovie-casevideo-v1-lq.mp4',
            type: 'video/mp4',
          },
        ],
      },
    ];

    this.state = {
      activeCaseId: cases[0].id,
      cases,
    };
  }

  setActiveCaseId = (id) => {
    this.setState({ activeCaseId: id });
  };

  render() {
    const { location } = this.props;
    const { activeCaseId, cases } = this.state;

    return (
      <>
        {/* Header and navigation */}
        <Switch>
          <Route
            exact
            path="/"
            render={(renderProps) => (
              <Header {...renderProps} invertColors minimalDisplay />
            )}
          />
          <Route exact render={(renderProps) => <Header {...renderProps} />} />
        </Switch>

        {/* Page content */}
        <PoseGroup>
          <RoutesContainer key={location.key || location.pathname}>
            <Switch location={location}>
              <Route
                exact
                path="/"
                render={(renderProps) => (
                  <Home
                    {...renderProps}
                    cases={cases}
                    onCaseHover={this.setActiveCaseId}
                    activeCaseId={activeCaseId}
                  />
                )}
              />
              <Route
                exact
                path="/cases"
                render={(renderProps) => (
                  <Cases
                    {...renderProps}
                    cases={cases}
                    onCaseHover={this.setActiveCaseId}
                    activeCaseId={activeCaseId}
                  />
                )}
              />
              <Route
                exact
                path="/cases/:caseId"
                render={(renderProps) => (
                  <Case
                    {...renderProps}
                    cases={cases}
                    setActiveCaseId={this.setActiveCaseId}
                  />
                )}
              />
              <Route
                path="/about"
                render={(renderProps) => <About {...renderProps} />}
              />
              <Route
                render={(renderProps) => (
                  <ErrorPage
                    {...renderProps}
                    status={404}
                    error="Sorry, we couldn't find what you're looking for.'"
                  />
                )}
              />
            </Switch>
          </RoutesContainer>
        </PoseGroup>

        {/* Background video */}
        <Route
          exact
          path="/"
          render={(renderProps) => (
            <BackgroundVideoList
              videoList={cases}
              activeVideoId={activeCaseId}
              {...renderProps}
            />
          )}
        />
        <Route
          path="/cases"
          render={(renderProps) => (
            <BackgroundVideoList
              videoList={cases}
              activeVideoId={activeCaseId}
              {...renderProps}
            />
          )}
        />
      </>
    );
  }
}
export default AppRoutes;
