import { css } from 'styled-components';

export const Hero = css`
  font-family: ${({ theme }) => theme.font.fontFamilyHead};
  font-size: 3em;
  font-size: 15vmin;
  font-weight: 600;
  text-transform: uppercase;

  @media screen and (min-width: 30rem) {
    font-size: 5em; /* fallback */
    font-size: 15vmin;
  }
  @media screen and (min-width: 56rem) {
    font-size: 8em; /* fallback */
    font-size: 15vmin;
  }
  @media screen and (min-width: 75rem) {
    font-size: 10em; /* fallback */
    font-size: 15vmin;
  }
`;

export const Head = css`
  font-family: ${({ theme }) => theme.font.fontFamilyHead};
  font-size: 2em; /* fallback */
  font-size: 9vmin;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;

  @media screen and (min-width: 42rem) {
    font-size: 3em; /* fallback */
    font-size: 8vmin;
  }
  @media screen and (min-width: 75rem) {
    font-size: 5em; /* fallback */
    font-size: 7vmin;
  }
`;

export const SubHead = css`
  font-family: ${({ theme }) => theme.font.fontFamilyHead};
  font-size: 1.5em;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
  line-height: 1;
`;
