import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';

const Content = styled.div`
  position: relative;
  top: -4em;
  overflow: hidden;

  @media screen and (min-width: 30rem) {
    top: -3em;
  }
  @media screen and (min-width: 42rem) {
    top: -6em;
  }
  @media screen and (min-width: 75rem) {
    top: -8em;
  }
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.font.fontFamilyHead};
  font-size: 2em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  padding-top: 0.75em;
  margin: 0 0 0.5em;
  text-shadow: ${({ theme }) => `0 0 0.1em ${rgba(theme.brand.Dark, 0.1)}`};

  @media screen and (min-width: 30rem) {
    font-size: 3em;
    padding-top: 0;
  }
  @media screen and (min-width: 42rem) {
    font-size: 4em;
  }
  @media screen and (min-width: 75rem) {
    font-size: 6em;
  }
`;

const Introduction = styled.p`
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.7;
  max-width: 40em;
  margin: 1em 0 2em;

  @media screen and (min-width: 30rem) {
    margin: 2em 0 3em;
  }
  @media screen and (min-width: 42rem) {
    margin: 2em 0 3em;
  }
  @media screen and (min-width: 75rem) {
    margin: 2em 0 3em;
  }
`;

const Head2 = styled.h2`
  font-size: 1.5em;

  @media screen and (min-width: 30rem) {
    font-size: 2em;
  }
`;

const VideoContainer = styled.div`
  width: calc(100vw - 2rem);
  height: calc((9 * (100vw - 2rem)) / 16);

  @media screen and (min-width: 42rem) {
    width: calc(90vw - 6rem);
    height: calc((9 * (90vw - 6rem)) / 16);
  }
  @media screen and (min-width: 75rem) {
    width: calc(90vw - 14rem);
    height: calc((9 * (90vw - 14rem)) / 16);
  }
`;

const ProjectDetail = ({ project: { title, description, youTubeId } }) => {
  return (
    <Content>
      <Title>{title}</Title>
      <Introduction>{description}</Introduction>
      <div>
        <Head2>Video</Head2>
        <VideoContainer>
          <iframe
            width="100%"
            height="100%"
            title={title}
            type="text/html"
            src={`https://www.youtube.com/embed/${youTubeId}`}
            frameBorder="0"
            allowFullScreen
          />
        </VideoContainer>
      </div>
    </Content>
  );
};

export default ProjectDetail;
