import React, { Component } from 'react';
import styled from 'styled-components';

import CaseInfo from 'components/CaseInfo/CaseInfo';

const Container = styled.div`
  flex: 0 1 auto;
  padding: 4rem 3.5rem 1rem;
`;

class ErrorPage extends Component {
  render() {
    return (
      <Container>
        <CaseInfo title="Not Found" subtitle={this.props.status} />
      </Container>
    );
  }
}

export default ErrorPage;
