import { string, arrayOf, shape } from 'prop-types';

export default shape({
  id: string.isRequired,
  title: string.isRequired,
  shortName: string.isRequired,
  description: string.isRequired,
  youTubeId: string.isRequired,
  sources: arrayOf(
    shape({
      src: string.isRequired,
      type: string.isRequired,
    })
  ).isRequired,
  poster: string.isRequired,
});
